import { Box, Text } from "@chakra-ui/layout"
import { Container } from "@chakra-ui/layout"
import * as React from "react"
import Layout from "../components/layout"
import SignupForm from "../components/forms/signup-form"
import { Spinner } from "@chakra-ui/spinner"
import { VStack } from "@chakra-ui/layout"
import queryString from "query-string"
import { Button } from "@chakra-ui/button"
import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/alert"

const baseUrl = process.env.GATSBY_API_DOMAIN || 'http://localhost:8888'
const url = `${baseUrl}/.netlify/functions/checkInvitation`;
const webinarId = process.env.GATSBY_WEBINAR_ID
const signulUrl = `${baseUrl}/.netlify/functions/signup`;


const checkInvitationCode = async (code) => {
  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ code })
  });

  return result.json();
}

// markup
const IndexPage = ({ location, data }) => {
  const [registredSession, setRegistredSession] = React.useState(false);
  console.log({ registredSession })
  const [invitation, setInvitation] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  const { code, rsvp } = React.useMemo(() => {
    const { code: invitCode, rsvp: initialRsvp } = queryString.parse(location.search);

    return { code: invitCode, rsvp: initialRsvp };
  })

  const onRSVPNo = React.useCallback(data => {
    setIsLoading(true);
    console.log({ invitation })
    fetch(signulUrl, {
      method: "POST",
      body: JSON.stringify({
        rsvp: false,
        webinar_id: webinarId,
        contact_id: `${invitation.contact_id}`
      })
    }).then(async response => {
      const data = await response.text()
      console.log({ data })
      setRegistredSession(data);
      setIsLoading(false);
    });
  }, [setRegistredSession, invitation]);

  const onSignup = React.useCallback(data => {
    setIsLoading(true);
    fetch(signulUrl, {
      method: "POST",
      body: JSON.stringify(data)
    }).then(async response => {
      const data = await response.json()
      setRegistredSession(data);
      setIsLoading(false);
    });
  }, [setRegistredSession]);

  React.useEffect(() => {
    if (!code) {
      setIsLoading(false);
      return;
    }

    checkInvitationCode(code).then(invitation => {
      setInvitation(invitation)
      setIsLoading(false);
    });
  }, [code])

  const isUsed = React.useMemo(() => invitation && invitation.rsvp, [invitation])

  if (!invitation && isLoading) {
    return (
      <Layout>
        <Container>
          <VStack align="center" key="loading"><Spinner alignSelf="center" size="xl" m={6} /></VStack>
        </Container>
      </Layout>
    )
  }

  if (registredSession && registredSession.registrant_id) {
    return (
      <Layout>
        <Container>
          <Box align="center" key="ok">
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Vous êtes inscrit !
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Vérifier votre boite mail
              </AlertDescription>
            </Alert>
          </Box>
        </Container>
      </Layout>
    )
  }

  if (isUsed) {
    return (
      <Layout>
        <Container>
          <Box align="center" key="already-used">
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Invitation déjà utilisée
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Cette invitation a déjà été utilisée mais vous pouvez nous envoyer une demande d’invitation à <a href="mailto:infos@affo.fr">infos@affo.fr</a><br/>
                Ce sera un plaisir de vous convier à nos rencontres.
              </AlertDescription>
            </Alert>
          </Box>
        </Container>
      </Layout>
    )
  }

  if (invitation && rsvp === 'no') {
    return (
      <Layout>
        <Container>
          <Box align="center" key="no-invitation" fontSize="lg">
            <Text mb={6}>Vous ne pourrez pas participer à notre évènement</Text>
            <Button colorScheme={ registredSession ? 'green' : 'brand' } size="lg" onClick={onRSVPNo} isLoading={isLoading} isDisabled={registredSession}>{ registredSession ? 'Réponse enregitrée' : 'Confirmer' } </Button>
          </Box>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Container>
        { invitation && (
          <Box key="with-invitation">
            <Box align="center">
              <Text>Inscrivez-vous en remplissant ce formulaire</Text>
            </Box>
            <SignupForm invitation={invitation} onSubmit={onSignup} webinarId={webinarId} isLoading={isLoading} />
          </Box>
        )}
        { (!invitation) && (
          <Box align="center" key="no-invitation">
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Invitation requise
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Pour vous inscrire, cliquez sur le lien reçu dans le mail d'invitation
              </AlertDescription>
            </Alert>
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
