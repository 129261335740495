import { Box } from "@chakra-ui/layout"
import * as React from "react"
import Header from "./header"

// markup
const Layout = ({ children }) => {
  return (
      <>
        <Header />
        <Box as="main">{ children }</Box>
      </>
  )
}

export default Layout
