import * as React from "react"
import { Box } from "@chakra-ui/layout"
import { FormControl } from "@chakra-ui/form-control"
import { FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { VStack } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import { Stack } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/layout"

import { useForm } from "react-hook-form"

// markup
const SignupForm = ({ invitation, isLoading, webinarId, onSubmit }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: invitation
  });

  return (
    <Box as="form" my="6" onSubmit={handleSubmit(onSubmit)} _disabled={true}>
      <VStack spacing="6">
        <input type="hidden" name="contact_id" ref={register({ required: true })} />
        <input type="hidden" name="webinar_id" value={webinarId} ref={register({ required: true })} />
        <Stack direction={["column", "row"]} justify="space-between" w="100%">
          <FormControl id="first_name">
            <FormLabel>Prénom</FormLabel>
            <Input name="first_name" type="text" ref={register({ required: true })} />
          </FormControl>
          <FormControl id="last_name">
            <FormLabel>Nom</FormLabel>
            <Input name="last_name" type="text" ref={register({ required: true })} />
          </FormControl>
        </Stack>
        <FormControl id="email">
          <FormLabel>Adresse email</FormLabel>
          <Input name="email" type="email" ref={register({ required: true })} />
        </FormControl>
        <FormControl id="org">
          <FormLabel>Société</FormLabel>
          <Input name="org"  type="text" ref={register()}/>
        </FormControl>
        <Flex width="100%" mt="4" justifyContent="flex-end">
          <Button type="submit" colorScheme="brand" size="lg" isLoading={isLoading}>Confirmer</Button>
        </Flex>
      </VStack>
    </Box>
  )
}

export default SignupForm


