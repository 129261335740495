import * as React from "react"
import { Heading, Text } from "@chakra-ui/layout"
import { Container } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { StaticImage } from "gatsby-plugin-image"
import { VStack } from "@chakra-ui/layout"

// markup
const Header = () => {
  return (
      <Box as="header" py="4" mb="8">
          <Container>
            <VStack spacing={8} justifyContent="center" alignItems="center" width="100%">
              <StaticImage src="../images/logo.png" width={264} />
              <Heading as="h1" size="lg" textAlign="center">Edition spéciale digitalisée des Rencontres Annuelles de l'AFFO</Heading>
              <Box>
                <Text as="h3" fontSize="lg" color="brand.500" textAlign="center" fontWeight="bold">Lundi 12 avril de 9h à 12h</Text>
                <Text fontSize="lg" color="brand.500" textAlign="center">(ouverture de la session zoom à 8h50)</Text>
              </Box>
            </VStack>
          </Container>
      </Box>
  )
}

export default Header
